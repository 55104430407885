
<mat-card class="card" >
      <mat-card-title class="mb10" >Wo finde ich die bluechip PC-Seriennummer</mat-card-title>
    <mat-card-content class="box">
        <img  class="img" src="assets/serialnumber-help/seriennummer2.png"/>
        <img  class="img" src="assets/serialnumber-help/seriennummer3.png"/>
        <a mat-raised-button color="accent"  href="https://www.bccontent.de/marketing/Videos/servicevideo/service.bluechip.de_v03.mp4">Videoanleitung</a>
    </mat-card-content>
    <mat-card-actions class="backbtn" >
        <button mat-raised-button color="primary" routerLink="/suche">Zurück</button>
    </mat-card-actions>
  </mat-card>
<div class="container box">
</div>


