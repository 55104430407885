import { Component } from '@angular/core';

@Component({
  selector: 'app-serialnumber-help',
  templateUrl: './serialnumber-help.component.html',
  styleUrls: ['./serialnumber-help.component.sass']
})
export class SerialnumberHelpComponent {

}
